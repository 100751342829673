import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PortalSelectProps } from '@interfaces/Select'
import Select from '@components/Select'
import { HeaderSelect } from './style'

const PageHeaderSelect: React.FC<{
  options?: PortalSelectProps[]
  onOptionChange(value: any): void
  hSelectLabel?: string
}> = ({ options, onOptionChange, hSelectLabel }) => {
  return (
    <HeaderSelect xs={12} md={5} lg={3}>
      <span>
        <FormattedMessage id={hSelectLabel || 'Sort order:'} />
      </span>
      <Select
        defaultValue={options?.filter((opt) => opt.defaultValue)?.[0]?.label}
        onChange={onOptionChange}
        options={options}
        disableMargin
        responsive
      />
    </HeaderSelect>
  )
}

export default PageHeaderSelect
