import gql from 'graphql-tag'

export const GET_SCHEDULES_PER_PROGRAM = gql`
  query GetSchedulesId($Id: String!, $FromDate: String!, $EndDate: String!, $ProgramIds: String) {
    getSchedulesId(
      Id: $Id
      FILTER_TYPE: "FILTER_LESSON_HISTORY"
      LIMIT: 10000
      FROM_DATE: $FromDate
      END_DATE: $EndDate
      ProgramIds: $ProgramIds
    ) {
      Materials {
        Chapter
        Material
        RecordId
        SessionSchedule
        Status
        Type
      }
      DeliveryMode
      EndTime
      Id
      LPId
      UnitId
      InstructorName
      ProgramId
      StartTime
      Status
      Timezone
      Grading
      Type
      VirtualClassRoomProvider
      OnlineLessonURL
      Attendance
      SessionId
      VirtualClassRoomId
      ZoomUsername
      NoOfLessons
    }
  }
`
