import IPortalRegistration from '@classes/Registration'
import { IGetEventsInput } from '@interfaces/Events'
import { ProgramStudent, RegistrationWrapper } from '@interfaces/Student'
import { REG_STATUS } from '@utils/constants'
import moment from 'moment'
import PortalProgram from './Program'
import PortalPrograms from './Programs'

class PortalRegistrations {
  registrations: RegistrationWrapper[]

  constructor(registrations: RegistrationWrapper[] = []) {
    this.registrations = registrations
  }

  studentSortedRegDropdown(regArr) {
    const StatusPriority = [REG_STATUS.COMPLETED, REG_STATUS.IN_PROGRESS, REG_STATUS.ACTIVE, REG_STATUS.PENDING_START]
    return regArr.sort((a, b) => {
      return StatusPriority.indexOf(b.data.Status) - StatusPriority.indexOf(a.data.Status)
    })
  }

  getRegistrationByProgId(programId: string) {
    return this.registrations?.find(registration =>
      registration?.RegistrationInfo.Programs?.some(prog => prog.ProgramId === programId)
    )?.RegistrationInfo
  }

  getAllRegistrationByProgId(programId: string) {
    return this.registrations?.filter(registration => {
      const programs = new PortalPrograms(
        registration.RegistrationInfo.Programs.map(ProgramStudentInfo => ({ ProgramStudentInfo })) as ProgramStudent[]
      )
      return programs.Programs?.some(prog => prog.ProgramId === programId && prog.IsActive)
    })
  }

  getRegistration(programId: string) {
    return new IPortalRegistration(this.getRegistrationByProgId(programId))
  }

  getRegistrationById(registrationId: string) {
    return new IPortalRegistration(
      this.registrations.find(
        ({ RegistrationInfo }) => RegistrationInfo.RegistrationId === registrationId
      )?.RegistrationInfo
    )
  }

  get RegistrationNames() {
    return this.registrations.map(({ RegistrationInfo }) => new IPortalRegistration(RegistrationInfo).RegistrationName)
  }

  get Languages() {
    return this.registrations.map(({ RegistrationInfo }) => RegistrationInfo.Language)
  }

  get ActiveRegistrations() {
    return this.registrations.filter(({ RegistrationInfo }) => new PortalProgram(RegistrationInfo.Programs[0]).IsActive)
  }

  get RegistrationDropdown() {
    return this.ActiveRegistrations.map(
      ({ RegistrationInfo }) => new IPortalRegistration(RegistrationInfo).RegistrationName
    )
  }

  get FirstItem() {
    return this.ActiveRegistrations.map(({ RegistrationInfo }) => new IPortalRegistration(RegistrationInfo))[0]
  }

  isFirstItemValidForOrientation = (ProgramStudentWrappers: ProgramStudent[] = [], Timezone) =>
    new PortalPrograms(ProgramStudentWrappers)
      ?.studentProgramsSortedDropdown(date => moment.tz(date ? date : moment(), Timezone))
      ?.find(({ data }) => this.FirstItem?.Programs?.some(({ ProgramId }) => ProgramId === data?.ProgramId))?.data
      ?.ValidforOrientation

  getEventAtteendeesPayload = (defaultPayload: IGetEventsInput) =>
    (this.ActiveRegistrations || [])?.map(({ RegistrationInfo }) => ({
      ...defaultPayload,
      RecordId: RegistrationInfo?.RegistrationId,
    })) || []

  getRegistrationProgramByProgId = (programId: string) =>
    this.getRegistrationByProgId(programId)?.Programs?.find(({ ProgramId }) => ProgramId === programId)
}

export default PortalRegistrations
