import { RegistrationInfo as IRegistrationInfo } from '@interfaces/Student'
import { DATE_FORMAT } from '@utils/constants'
import { flatten } from 'lodash'
import moment from 'moment'

class PortalRegistration {
  registration?: IRegistrationInfo
  constructor(registration?: IRegistrationInfo) {
    this.registration = registration
  }

  get ProgramType() {
    return this.registration?.Programs?.[0]?.ProgramType || '' // assumes 1 program per registration
  }

  get RegistrationName() {
    return {
      label: this.registration?.DisplayName ?? this.registration?.Name,
      value: this.registration?.RegistrationId,
      data: this.registration,
    }
  }

  get RegistrationId() {
    return this.registration?.RegistrationId || ''
  }

  get MinContractEndDate() {
    return moment
      .min(
        flatten(this.registration?.Programs.map(({ ContractEndDate }) => moment(ContractEndDate, DATE_FORMAT.BASIC)))
      )
      .format(DATE_FORMAT.BASIC)
  }

  get MaxContractEndDate() {
    return moment
      .max(
        flatten(this.registration?.Programs.map(({ ContractEndDate }) => moment(ContractEndDate, DATE_FORMAT.BASIC)))
      )
      .format(DATE_FORMAT.BASIC)
  }

  get Programs() {
    return this.registration?.Programs || []
  }

  get ValidforOrientation() {
    return this.Programs?.some(({ ValidforOrientation }) => ValidforOrientation)
  }
}

export default PortalRegistration
