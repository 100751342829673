import styled from 'styled-components'
import { Col } from 'react-styled-flexboxgrid'

export const HeaderSelect = styled(Col)`
  padding-right: 0;
  padding-left: 0;
  align-items: center;
  display: flex !important;
  margin-left: auto;
  & > span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    margin-right: ${({ theme }) => theme.space.xs};
  }
`
