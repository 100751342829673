import MyBerlitzLesson from '@classes/Lesson'
import { ScheduleSession } from '@interfaces/ScheduleSession'
import { CANCELLED_LESSON_STATUS, LESSON_STATUS } from '@utils/constants'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import moment from 'moment-timezone'
import { uniq, flatMap } from 'lodash'
import { InstructorSearchResultInfo } from '@interfaces/Instructor'

class MyBerlitzLessons {
  lessons?: ScheduleSession[]
  tz?: string

  constructor(lessons?: ScheduleSession[], tz?: string) {
    this.lessons = lessons
    this.tz = tz
  }

  validSessions = () =>
    orderBy(
      this.lessons?.filter(({ Status }) => !includes(CANCELLED_LESSON_STATUS, (Status || '').toLowerCase())) || [],
      'StartTime'
    )

  groupLessonsBySession = () =>
    this.validSessions().reduce((r, a) => {
      r[a.SessionId] = [...(r[a.SessionId] || []), a]
      return r
    }, {})

  simplifiedSession = (): MyBerlitzLesson[] => {
    const grouped = this.groupLessonsBySession()
    const keys = Object.keys(grouped) || []
    return keys.map(
      (session) =>
        new MyBerlitzLesson(
          {
            ...grouped[session]?.[0],
            _lastLessonEndTime: grouped[session]?.[grouped[session]?.length - 1]?.EndTime,
            _lessonCount: grouped[session]?.length,
          },
          undefined,
          this.tz
        )
    )
  }

  getLPSessions = (LPId: string) => {
    return this.Sessions.filter((session) => session.IsFlexSession && session.lesson?.LPId === LPId)
  }

  get OngoingLPSession() {
    return this.Sessions.find((session) => session.IsFlexSession && !moment().isAfter(moment(session.lesson?.EndTime)))
  }

  get HasOngoingLPSession() {
    return Boolean(this.OngoingLPSession)
  }

  get _firstSession() {
    return this.simplifiedSession()?.[0]
  }

  get Sessions() {
    return this.validSessions().map((session) => new MyBerlitzLesson(session, undefined, this.tz))
  }

  get FlexSessions() {
    return this.Sessions.filter((session) => session.IsFlexSession)
  }

  get FirstActiveSession() {
    const session = this.Sessions.find((Session) =>
      LESSON_STATUS.CAN_JOIN.includes(Session?.Status?.toLocaleLowerCase() ?? '')
    )

    return moment(session?.EndTime).isAfter() ? session : null
  }

  get DesiredPastInstructorOrder() {
    return uniq(
      this.validSessions()
        .filter((session) => session.InstructorName && session.EndTime)
        .sort((a, b) => new Date(b.EndTime || '').valueOf() - new Date(a.EndTime || '').valueOf())
        .map((session) => session.InstructorName)
    )
  }

  getOrderedPastInstructors = (instructors: InstructorSearchResultInfo[]) => {
    // attempt to order past instructors by most recent
    const instructorWithSession = flatMap(this.DesiredPastInstructorOrder, (name) =>
      instructors.filter((instructor) => instructor.Name === name)
    )
    // append the instructors with no sessions
    const instructorWithoutSession = instructors.filter(
      (instructor) => !instructorWithSession.find((res) => res.Name === instructor.Name)
    )
    return instructorWithSession.concat(instructorWithoutSession)
  }
}

export default MyBerlitzLessons
