import { H1 } from '@berlitz/globals'
import Spacer from '@berlitz/spacer'
import HR from '@components/HR'
import PageHeaderSelect from '@components/PageHeaderSelect'
import ProfileClock from '@components/ProfileClock'
import { PortalSelectProps } from '@interfaces/Select'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

const ClockLoader = styled.div`
  width: 100%;
`

const Header = styled.div<{ noClock?: boolean; selectOptions?: PortalSelectProps[] }>`
  & > *:last-child {
    margin-bottom: ${({ theme }) => theme.space.xl};
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: ${({ noClock, selectOptions }) => (noClock && !selectOptions ? 'block' : 'flex')};
    align-items: center;

    margin-bottom: ${({ theme }) => theme.space.lg};
    & > *:last-child {
      margin-left: auto;
      margin-bottom: 0;
    }

    ${({ noClock, selectOptions }) =>
      !noClock &&
      !selectOptions &&
      css`
        h1 {
          margin-bottom: 0;
          line-height: 1;
        }
      `}

    ${({ selectOptions }) =>
      selectOptions &&
      css`
        h1 {
          margin-bottom: 0;
        }
      `}

    ${ClockLoader} {
      width: 380px;
    }
  }
`

const PageHeader: React.FC<{
  title?: string
  warningModal?: boolean
  noClock?: boolean
  selectOptions?: PortalSelectProps[]
  onOptionChange?: (value: any) => void
  noHR?: boolean
  loading?: boolean
  timezone?: string
  isAdmin?: boolean
}> = ({ title, warningModal, noClock, selectOptions, onOptionChange, noHR, loading, timezone, isAdmin }) => {
  return (
    <>
      <Header noClock={noClock} selectOptions={selectOptions}>
        {title ? <H1>{loading ? <Skeleton width="168px" height="32px" /> : <FormattedMessage id={title} />}</H1> : null}
        {!noClock ? (
          loading ? (
            <ClockLoader>
              <Skeleton width="100%" height="32px" />
            </ClockLoader>
          ) : (
            <ProfileClock warningModal={warningModal} timezone={timezone} hideChangeLink={loading || isAdmin} />
          )
        ) : selectOptions && onOptionChange ? (
          <PageHeaderSelect onOptionChange={onOptionChange} options={selectOptions} />
        ) : !noHR ? (
          <HR />
        ) : null}
      </Header>
      {selectOptions && onOptionChange ? (
        <>
          <HR />
          <Spacer size="xs" />
        </>
      ) : null}
    </>
  )
}

export default PageHeader
